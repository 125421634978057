<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 90px 120px;">
      <div class="main">
        <div class="main-head" @click="jumpBack">
          <img class="left-right" src="../../assets/market/l.png" alt="">
          <div class="primary" v-if="type == 1">{{ $t('my.xg') }}{{ $t('my.dlma') }}</div>
          <div class="primary" v-if="type == 2">{{ $t('my.xqyx') }}</div>
          <div class="primary" v-if="type == 3">{{ $t('my.sz') }}{{ $t('my.jyma') }}</div>

        </div>
        <div class="line"></div>
        <div class="man-box">
          <!-- <div class="hint">{{ $t('my.ts') }}</div> -->
          <div v-if="type == 1">
            <div class="man-box-son">
              <input type="password" v-model="oldPwd" v-if="isCheck" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr1')">
              <input type="text" v-model="oldPwd" v-else class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr1')">
              <div @click="check">
                <img class="close-img" v-if="isCheck" src="../../assets/market/close.png" alt="">
                <img class="close-img" v-else src="../../assets/market/open.png" alt="">
              </div>
            </div>
            <div class="man-box-son">
              <input type="password" v-model="newPwd" v-if="isCheck2" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr2')">
              <input type="text" v-model="newPwd" v-else class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr2')">
              <div @click="check2">
                <img class="close-img" v-if="isCheck2" src="../../assets/market/close.png" alt="">
                <img class="close-img" v-else src="../../assets/market/open.png" alt="">
              </div>
            </div>
            <div class="man-box-son" style="margin-bottom: 50px;">
              <input type="password" v-model="newPwd2" v-if="isCheck3" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr3')">
              <input type="text" v-model="newPwd2" v-else class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.qsr3')">
              <div @click="check3">
                <img class="close-img" v-if="isCheck3" src="../../assets/market/close.png" alt="">
                <img class="close-img" v-else src="../../assets/market/open.png" alt="">
              </div>
            </div>
            <div class="submit-flex">
              <div class="submit" @click="jumpBack" style="margin-right: 22px;background: #29343D;color: #FFF;">
                {{ $t('my.qx') }}
              </div>
              <div class="submit" @click="editPwd">{{ $t('my.qd') }}</div>
            </div>
          </div>
          <div v-if="type == 2">
            <div class="man-box-son">
              <input type="text" class="fs-16" style="color: #FFF;width: 100%;" :placeholder="$t('my.sryx')">
              <!-- <img class="close-img"  src="../../assets/market/close.png" alt=""> -->
            </div>
            <div class="man-box-son">
              <input type="text" class="fs-16" style="color: #FFF;width: 100%;" :placeholder="$t('my.sryx2')">
              <!-- <img class="close-img"  src="../../assets/market/close.png" alt=""> -->
            </div>
            <div class="man-box-son" style="margin-bottom: 50px;">
              <input type="text" class="fs-16" style="color: #FFF;width: 100%;" :placeholder="$t('my.sryx3')">
              <!-- <img class="close-img"  src="../../assets/market/close.png" alt=""> -->
            </div>
            <div class="submit-flex">
              <div class="submit" style="margin-right: 22px;background: #29343D;color: #FFF;">{{ $t('my.qx') }}</div>
              <div class="submit">{{ $t('my.qd') }}</div>
            </div>
          </div>
          <div v-if="type == 3">
            <div class="man-box-son">
              <input type="password" v-if="isCheck4" class="fs-16" v-model="payPass" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.szjyma')">
              <input type="text" v-else v-model="payPass" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.szjyma')">
              <div @click="check4">
                <img class="close-img" v-if="isCheck4" src="../../assets/market/close.png" alt="">
                <img class="close-img" v-else src="../../assets/market/open.png" alt="">
              </div>
            </div>
            <div class="man-box-son">
              <input type="password" v-model="payPass2" v-if="isCheck5" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.szjyma')">
              <input type="text" v-else v-model="payPass2" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.szjyma2')">
              <div @click="check5">
                <img class="close-img" v-if="isCheck5" src="../../assets/market/close.png" alt="">
                <img class="close-img" v-else src="../../assets/market/open.png" alt="">
              </div>
            </div>
            <div class="man-box-son" style="margin-bottom: 50px;">
              <input type="text" v-model="code" class="fs-16" style="color: #FFF;width: 100%;"
                     :placeholder="$t('my.sryzm')">
              <el-button :disabled="disabled" @click="getCode">{{ !codeTime ? $t('login.yzm') : codeTime + 's' }}
              </el-button>
            </div>
            <div class="submit-flex">
              <div class="submit" @click="jumpBack" style="margin-right: 22px;background: #29343D;color: #FFF;">
                {{ $t('my.qx') }}
              </div>
              <div class="submit" @click="editPass">{{ $t('my.qd') }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import {getMemberInfo, setPassword, setPayPass} from "@/api/member";
import {sendEmail} from "@/api/conmmon";

export default {
  data() {
    return {
      getData: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      activeIndex: 0,
      value: '',
      isCheck: true,
      isCheck2: true,
      isCheck3: true,
      isCheck4: true,
      isCheck5: true,
      disabled: false,
      account: '',//账号
      oldPwd: '',
      newPwd: '',
      newPwd2: '',
      payPass: '',
      payPass2: '',
      code: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      type: 1,
      codeTime: 0
    }
  },
  components: {
    footerb,
    heads
  },
  created() {
    this.type = this.$route.query.type
    this.getInfo()
  },
  methods: {
    btn(id) {
      this.activeIndex = id
    },
    check() {
      this.isCheck = !this.isCheck
    },
    check2() {
      this.isCheck2 = !this.isCheck2
    },
    check3() {
      this.isCheck3 = !this.isCheck3
    },
    check4() {
      this.isCheck4 = !this.isCheck4
    },
    check5() {
      this.isCheck5 = !this.isCheck5
    },
    jumpBack() {
      this.$router.push({
        path: '/my',
        query: {
          activeIndex: 3
        }
      })
    },
    getInfo() {
      getMemberInfo().then(res => {
        if (res.code === 1) {
          this.account = res.data.email
        }
      })
    },
    getCode() { //验证码
      if (this.codeTime > 0) {
        return;
      }
      this.disabled = true;
      sendEmail({
        email: this.account,
        type: 'set_pay_pass'
      }).then(res => {
        if (res.code === 1) {
          this.codeTime = 59;
          let timer = setInterval(() => {
            this.codeTime--;
            if (this.codeTime < 1) {
              clearInterval(timer);
              this.codeTime = 0
              this.disabled = false;
            }
          }, 1000)
        } else {
          this.disabled = false;
        }
      })

    },
    editPwd() { //修改密码
      setPassword({
        old_password: this.oldPwd,
        password: this.newPwd,
        pass_two: this.newPwd2
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.jumpBack()
          }, 800)
        }
      })

    },
    editPass() { //修改交易密码
      setPayPass({
        pay_pass: this.payPass,
        pass_two: this.payPass2,
        code: this.code
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.jumpBack()
          }, 800)
        }
      })

    },
  }
};

</script>
<style scoped>
html {
  background: #171e28;
  font-size: 16px;
}

.fs-16{
  font-size: 16rpx;
}

.main {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background: #1F282F;
}

.main-head {
  display: flex;
  align-items: center;
  padding: 22px 0 20px 40px;
}

.primary {
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}

.left-right {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #171E28;
  margin-bottom: 36px;
}

.man-box {
  width: 100%;
  padding-bottom: 44px;
}

.man-box-son {
  width: 348px;
  margin: 0 auto;
  color: #FFFFFF;
  border-bottom: 1px solid #171E28;
  padding-bottom: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}


.submit {
  width: auto;
  padding: 0 20px;
  height: 48px;
  border-radius: 2px;
  background: #FFC300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #212121;
  cursor: pointer;
  box-sizing: border-box;
}

.hint {
  font-size: 14px;
  color: #FF6B65;
  text-align: center;
  margin-bottom: 26px;
}

.submit-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-img {
  width: 20px;
  height: 20px;
}

.el-button {
  background: none !important;
  padding: 6px 12px;
  color: #FFC300;
  font-size: 14px !important;
  border: 1px solid #FFC300;
}
</style>
