import request from '@/utils/request'

const url = 'api/common/'

//获取阿里云权限 get
export const sendEmail = (data) => request(url+'email','get',data,true)

export const base64Image = (data) => request(url+'base64','post',data,true)
export const fileImage = (data) => request(url+'file','post',data,true)
export const getSysMessage = (data) => request(url+'message','post',data,true)
export const getPrivacyInfo = (data) => request(url+'agreement','post',data,true)
